@import '../../../styles/var';

.reviews {
    position: relative;

    .title {
        max-width: 600px;

        @media(max-width: $md4+px) {
            max-width: 500px;
        }

        @media(max-width: 600px) {
            text-align: center;
            margin: 0 auto;
        }
    }

    .swiperWrap {
        position: relative;
        margin-top: 60px;

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        .card {
            border-radius: 15px;
            background: #1E222D;
            padding: 24px;

            .header {
                display: flex;
                align-items: center;
                gap: 15px;

                .icon {
                    width: 40px;
                }

                .name {
                    font-weight: 400;
                }

                .stars {
                    width: 94px;
                }
            }

            .text {
                color: rgba(255, 255, 255, 0.70);
                margin: 18px 0;
            }

            .datetime {
                color: #8C8C8C;
                font-family: $inter;
                font-size: 10px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }

        .navigation {
            position: absolute;
            top: -93px;
            right: 0;
            display: flex;
            align-items: center;
            gap: 20px;

            @media(max-width: $md5+px) {
                position: relative;
                top: unset;
                right: unset;
                justify-content: center;
                margin-top: 20px;
            }

            .nav {
                cursor: pointer;
                width: 33px;
                height: 33px;
                position: relative;
                background-image: url('../../../assets/img/HomePage/Review/arrow.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                &:hover {
                    opacity: 0.8;
                }

                &L {}

                &R {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

:global(.reviewsSwiper) {
    overflow: visible !important;

    @media(max-width: 800px) {
        height: 100%;
    }
}

:global(.reviewsSwiperSlide) {
    height: 100%;

    @media(max-width: 800px) {
        height: auto;
    }
}