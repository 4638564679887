@import "../../../styles/var";

.button {
    padding: 14px 34px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    background: #F98F1D;
    border-radius: 8px;
    font-family: $inter;
    font-weight: 800;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    transition: all 0.3s;
    border: 1px solid #F98F1D;

    color: #11141B;
    font-family: $inter;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }

    &:hover {
        color: #F98F1D;
        background: transparent;
    }

    &:active {
        background: rgba($color: #F98F1D, $alpha: 0.2);
        transition: all .1s;
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}