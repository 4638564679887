@import '../../../styles/var';

.everything {
    position: relative;
    .content {
        max-width: 525px;
        .title {
    
        }
        .subtitle {
            margin: 20px 0 40px;
            font-weight: 600;
            max-width: 392px;
            @media(max-width: $md6+px) {
                margin: 12px 0 20px;
            }
        }
        .text {
    
        }
    }
    .image {
        position: absolute;
        right: 0;
        top: -60px;
        width: 506px;
        @media(max-width: $md3+px) {
            width: 450px;
            top: -20px;
            right: -50px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            top: unset;
            right: unset;
            display: flex;
            margin: 40px auto 0 auto;
        }
        @media(max-width: $md6+px) {
            width: 335px;
        }
    }
}