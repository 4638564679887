@import '../../../styles/var';

.whoCreates {
    position: relative;

    .title {
        max-width: 590px;
        margin: 0 auto;
        text-align: center;
    }

    .cards {
        margin: 60px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 20px;

        @media(max-width: 600px) {
            display: flex;
            flex-direction: column;
        }

        @media(max-width: $md6+px) {
            margin: 40px 0;
        }

        .card {
            height: 256px;
            border-radius: 15px;
            background: #1E222D;
            display: flex;
            align-items: center;
            padding-left: 40px;

            @media(max-width: $md4+px) {
                flex-direction: column;
                justify-content: center;
                height: auto;
                padding: 40px 20px 0;
            }

            .text {
                max-width: 316px;

                @media(max-width: $md4+px) {
                    max-width: 100%;
                }
            }
            .image {
                height: 100%;
                @media(max-width: $md3+px) {
                    height: 220px;
                }
            }
        }
    }

    .descr {
        max-width: 456px;
        margin: 0 auto;
        color: #FFF;
        font-family: $sans;
        font-weight: 600;
        text-align: center;
        @media(max-width: $md6+px) {
            font-size: 12px;
        }
    }
}