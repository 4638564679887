@import '../../../styles/var';

.whatAre {
    .content {
        display: grid;
        grid-template-columns: 525px 604px;
        justify-content: space-between;
        gap: 20px;
        @media(max-width: $md3+px) {
            grid-template-columns: 500px 1fr;
        }
        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
        }
        .title {
            
        }
        .text {

        }
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 500px);
        gap: 20px;
        margin-top: 40px;
        @media(max-width: $md4+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }
        .card {
            position: relative;
            border-radius: 15px;
            height: 100%;
            padding: 30px;
            @media(max-width: $md6+px) {
                padding: 20px;
            }
            &:nth-child(1) {
                background: #1E222D;
                .cardImage {
                    bottom: 0;
                }
            }
            &:nth-child(2) {
                background: #01C2FD;
                display: flex;
                align-items: flex-end;
                @media(max-width: $md4+px) {
                    align-items: initial;
                }
                .cardTitle {
                    color: rgba(0, 0, 0, 0.70);
                }
                .cardText {
                    color: rgba(0, 0, 0, 0.70);
                }
                .cardImage {
                    top: 0;
                }
            }
            &:nth-child(3) {
                background: #303542;
                .cardImage {
                    bottom: 0;
                }
            }
            &Content {
                .cardIcon {
                    width: 50px;
                }
                .cardTitle {
                    margin: 25px 0 15px;
                    @media(max-width: $md6+px) {
                        margin: 20px 0 8px;
                    }
                }
                .cardText {

                }
            }
            &Image {
                position: absolute;
                width: 100%;
                left: 0;
                @media(max-width: $md4+px) {
                    display: none;
                }
            }
        }
    }
}