@import "../../../styles/_var.scss";

.header {
    position: sticky;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s;
    background-color: rgba($color: #11141B, $alpha: 0.4);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.50);


    &.hideBg {
        background-color: rgba($color: #11141B, $alpha: 0);
        backdrop-filter: blur(0);
    }
    &.lightBg {
        .headerRowLinks {
            a {
                color: #fff;
            }
            .linkActive {
                color: #7A51AF;
            }
        }
        :global(.Dropdown-control) {
            color: #fff !important;
        }
        :global(.Dropdown-arrow) {
            border-color: #fff transparent transparent !important;
        }
        :global(.is-open .Dropdown-arrow) {
            border-color: transparent transparent #fff !important;
        }
    }
    @media (max-width: $md3+px) {
        height: 83px;
    }
    @media (max-width: $md6+px) {
        height: 57px;
    }
    &::before {
        content: "";
        background: #FFFFFF;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        transition: all 0.3s;
        z-index: -1;
    }
    &Active {
        height: 100%;
        &::before {
            opacity: 1;
            height: 100%;
        }
        .langs {
            display: none;
        }
        path {
            fill: white;
        }
    }
    .headerRow {
        display: grid;
        grid-template-columns: 109px auto;
        gap: 60px;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        @media (max-width: $md6+px) {
            padding: 10px 0;
        }
        &Logo {
            @media (max-width: $md3+px) {
                width: 109px;
            }
            @media(max-width: $md6+px) {
                width: 80px;
            }
            img {
                width: 100%;
            }
        }
        &Content {
            display: flex;
            align-items: center;
            .langs {

            }
        }
    }
}
