@import "./_var.scss";

body,
html {
    background-color: #11141B;
    color: #fff;

    &.lock {
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #11141B;
    }

    ::-webkit-scrollbar-thumb {
        background: #01C2FD;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #006b8b;
    }
}

.font-96 {
    color: #01C2FD;
    font-family: $inter;
    font-size: 96px;
    font-weight: 900;
    line-height: 130%;

    @media(max-width: $md4+px) {
        font-size: 54px;
    }

    @media(max-width: $md6+px) {
        font-size: 36px;
    }
}

.font-48 {
    color: #01C2FD;
    font-family: $inter;
    font-size: 48px;
    font-weight: 700;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 38px;
    }

    @media(max-width: $md6+px) {
        font-size: 28px;
    }
}

.font-32 {
    color: #D4F4FF;
    font-family: $inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 24px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-20 {
    color: #FFF;
    font-family: $sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-20-whatare {
    color: #01C2FD;
    font-family: $sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-16 {
    color: #FFF;
    font-family: $sans;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mt {
    margin-top: 160px;

    @media (max-width: $md4+px) {
        margin-top: 120px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.Dropdown-control {
    font-family: $inter !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
    text-transform: uppercase !important;
    padding-right: 30px !important;
    cursor: pointer !important;

    &:hover {
        box-shadow: none !important;
    }
}

.Dropdown-menu {
    font-family: $inter !important;
    font-style: normal !important;
    font-size: 14px !important;
}

.Dropdown-arrow {
    border-color: #fff transparent transparent !important;
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #fff !important;
}