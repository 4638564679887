@import '../../../styles/var';

.whyUse {
    position: relative;

    .content {
        .title {
            max-width: 600px;
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-top: 45px;

            @media(max-width: $md4+px) {
                gap: 30px;
            }

            @media(max-width: $md6+px) {
                margin-top: 40px;
                gap: 20px;
            }

            li {
                position: relative;
                padding-left: 64px;
                max-width: 500px;

                @media(max-width: $md4+px) {
                    max-width: 410px;
                    padding-left: 50px;
                }

                @media(max-width: $md6+px) {
                    padding-left: 44px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: -1px;
                    width: 32px;
                    height: 32px;
                    background-image: url('../../../assets/img/HomePage/WhyUse/check.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    @media(max-width: $md4+px) {
                        width: 28px;
                        height: 28px;
                        top: -2px;
                    }

                    @media(max-width: $md6+px) {
                        width: 22px;
                        height: 22px;
                        top: -1px;
                    }
                }
            }
        }
    }

    .images {
        position: absolute;
        right: 126px;
        top: -20px;
        width: 445px;

        @media(max-width: $md3+px) {
            width: 400px;
            top: 0;
            right: 40px;
        }

        @media(max-width: $md4+px) {
            width: 300px;
            right: 20px;
            top: 10px;
        }

        @media(max-width: 830px) {
            position: relative;
            right: unset;
            top: unset;
            display: flex;
            margin: 40px auto 0;
            transform: translateX(-20px);
            width: 295px;
        }

        .man {
            position: relative;
            z-index: 3;
            width: 100%;
        }

        .image {
            position: absolute;
            z-index: 2;
            top: 35px;
            right: -126px;
            width: 300px;

            @media(max-width: $md3+px) {
                width: 270px;
                right: -110px;
                top: 25px;
            }

            @media(max-width: $md4+px) {
                width: 198px;
                top: 23px;
                right: -83px;
            }
        }

        .blur {
            position: absolute;
            background: #01C2FD;
            filter: blur(60px);
            width: 130px;
            height: 130px;
            top: 70px;
            left: 100px;

            @media(max-width: $md2+px) {
                width: 100px;
                height: 100px;
                left: 80px;
                filter: blur(55px);
            }
            @media(max-width: $md4+px) {
                width: 80px;
                height: 80px;
                filter: blur(40px);
                left: 60px;
                top: 50px;
            }
            @media(max-width: $md6+px) {
                filter: blur(30px);
                width: 60px;
                height: 60px;
                top: 60px;
            }
        }
    }
}