@import "../../../styles/var";

.footerWrap {
    position: relative;
    color: rgba(255, 255, 255, 0.70);
    font-family: $inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    background: #1E222D;
    padding: 40px 60px 20px;
    border-radius: 15px 15px 0 0;

    @media(max-width: $md3+px) {
        padding: 40px 40px 20px;
    }

    @media(max-width: $md4+px) {
        padding: 30px 24px 40px;
    }

    @media(max-width: $md6+px) {
        padding: 20px 20px 40px;
    }

    .cols {
        display: grid;
        grid-template-columns: 312px 1fr;
        gap: 44px;

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        .col {
            .descr {
                font-weight: 600;
                color: #fff;
            }

            .docs {
                margin-top: 25px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                @media(max-width: $md4+px) {
                    margin-top: 20px;
                }

                a {
                    color: rgba(255, 255, 255, 0.70);
                    text-decoration: none;

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .disclaimer {
                font-size: 12px;
                font-weight: 300;
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            .address {
                font-weight: 300;
                margin-top: 12px;

                b {
                    font-weight: 500;
                }
            }
        }
    }

    .rights {
        text-align: right;
        margin-top: 20px;

        @media(max-width: $md4+px) {
            text-align: center;
        }
    }
}