@import '../../../styles/var';

.bannerWrap {}

.banner {
    position: relative;
    padding: 80px 0 100px;

    @media(max-width: $md3+px) {
        padding-bottom: 0;
    }

    @media(max-width: $md6+px) {
        padding-top: 40px;
    }

    .content {
        position: relative;
        z-index: 10;

        .subtitle {
            margin: 8px 0 40px;

            @media(max-width: $md4+px) {
                margin: 8px 0 30px;
            }

            @media(max-width: $md6+px) {
                margin: 8px 0 12px;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            max-width: 525px;

            @media(max-width: $md6+px) {
                gap: 8px;
            }
        }

        .download {
            &Text {
                font-weight: 600;
                margin: 70px 0 20px;
                font-family: $inter;

                @media(max-width: $md4+px) {
                    margin-top: 50px;
                }

                @media(max-width: $md6+px) {
                    margin-top: 40px;
                }
            }
        }
    }

    .images {
        position: absolute;
        right: 40px;
        bottom: 0;
        width: 486px;

        @media(max-width: $md3+px) {
            position: relative;
            display: flex;
            bottom: unset;
            margin: 40px auto 0;
            width: 360px;
            transform: translateX(35px);
            right: 0;
        }

        @media(max-width: 600px) {
            width: 244px;
            transform: translateX(20px);
        }

        .woman {
            position: relative;
            z-index: 3;
            width: 100%;
        }

        .cards {
            position: absolute;
            z-index: 4;
            width: 310px;
            bottom: 77px;
            left: -125px;

            @media(max-width: $md3+px) {
                width: 260px;
                bottom: 60px;
                left: -110px;
            }
            
            @media(max-width: 600px) {
                width: 155px;
                bottom: 38px;
                left: -63px;
            }
        }

        .bell_01 {
            position: absolute;
            z-index: 5;
            width: 135px;
            bottom: 85px;
            left: 100px;

            @media(max-width: $md3+px) {
                width: 100px;
                left: 90px;
                bottom: 75px;
            }

            @media(max-width: 600px) {
                width: 70px;
                bottom: 43px;
                left: 50px;
            }
        }

        .bell_02 {
            position: absolute;
            z-index: 2;
            width: 136px;
            bottom: 280px;
            right: -54px;

            @media(max-width: $md3+px) {
                width: 100px;
                bottom: 210px;
                right: -42px;
            }

            @media(max-width: 600px) {
                width: 70px;
                right: -30px;
                bottom: 140px;
            }
        }

        .light {
            position: absolute;
            z-index: 1;
            width: 244px;
            height: 244px;
            background: #FF6B00;
            filter: blur(100px);
            top: 55px;
            right: 18px;
            border-radius: 100px;
            @media(max-width: $md3+px) {
                filter: blur(50px);
                width: 120px;
                height: 120px;
                top: 80px;
                right: 0;
            }
            @media(max-width: 600px) {
                filter: blur(30px);
                width: 60px;
                height: 60px;
                top: 100px;
            }
        }
    }
}