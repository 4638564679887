@import '../../../styles/var';

.takeYour {
    position: relative;
    .image {
        position: absolute;
        left: -150px;
        top: -95px;
        width: 694px;
        @media(max-width: $md3+px) {
            width: 530px;
            top: -10px;
        }
        @media(max-width: 1100px) {
            width: 480px;
            top: 15px;
            left: -200px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            margin-top: 40px;
            left: unset;
            top: unset;
        }
        @media(max-width: 600px) {
            width: 440px;
            left: -60px;
        }
        @media(max-width: $md6+px) {
            width: 390px;
        }
    }
    .content {
        width: 604px;
        margin-left: auto;
        @media(max-width: $md4+px) {
            width: 100%;
        }
        .title {

        }
        .text {
            margin: 20px 0 60px 0;
            @media(max-width: $md6+px) {
                margin-bottom: 40px;
            }
        }
        .subtitle {
            font-weight: 600;
        }
        .buttons {
            margin-top: 40px;
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
}